import styled from 'styled-components';

import background from '../../assets/images/background.png';

export const Header = styled.div`
    background: #113E41;
    width: 100%;
    height: 120px;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    
`

export const HeaderContent = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0px 80px;
    @media screen and (max-width: 610px) {
        margin: 0px 20px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const LeftHeader = styled.div``;

export const RightHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%
`;

export const Option = styled.div`
    cursor: pointer;
    padding: 16px;
    font-family: Yanone Kaffeesatz;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
`;

export const Underline = styled.div`
    height: 1px;
    width: 60%;
    background: #F7D972;
    margin: 0 auto;
`;

export const Logo = styled.img`
    @media screen and (max-width: 610px) {
        width: 200px;
    }

`;

export const Phone = styled.div`
    background: #0A282B;
    border: 1px solid #4BB975;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 12px 16px;

    p {
        color: #fff;
    }
`;

export const Principal = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #0c5244 0%, rgba(12, 82, 68, 0) 100%),
        url(${background});
`;

export const Content = styled.div`
    padding: 40px 80px;
    width: 100%;
    max-width: 1440px;
`;

export const Phrase = styled.div``;

export const Quote = styled.p`
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
`;

export const Author = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
`;

export const Informations = styled.div`
    display: flex;
    @media screen and (max-width: 1110px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1109px) {
        align-items: center;
        flex-direction: column;
    }
    margin-top: 34px;
`;

export const LeftInformation = styled.div`
    background: #fff;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    max-width: 65%;

    @media screen and (max-width: 1109px) {
        margin-bottom: 10px;
        max-width: 100%;
    }

    @media screen and (max-width: 610px) {
        padding: 10px;
    }

    h2 {
        font-style: normal;
        font-weight: 200;
        font-size: 64px;
        line-height: 63px;
        display: flex;
        align-items: center;

        /* Dark-Green */

        color: #113E41;
    }

    ul {
        margin-top: 24px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 610px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        li {
            width: 50%;
            @media screen and (max-width: 610px) {
                width: 100%;
            }
            padding: 8px 0px;
            color: #7F8A84;
            display: flex;
            align-items: center;

            p {
                padding-left: 6px;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 18px;
            }
        }
    }
`;

export const RightInformation = styled.div`
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 10px;
    margin-left: 24px;
    width: 35%;
    height: 404px;
    @media screen and (max-width: 610px) {
        height: auto;
    }

    @media screen and (max-width: 1109px) {
        margin-bottom: 10px;
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
    }

`;

export const BottomInformations = styled.div`
    margin-top: 80px;
    display: flex;

    @media screen and (max-width: 610px) {
        flex-direction: column;
    }

    a {
        text-decoration: none;
    }

`;

export const Doctorlia = styled.a`
    color: #4BB975;
    border: 1px solid #4BB975;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    padding: 14px;
    display: flex;
    @media screen and (max-width: 610px) {
        justify-content: center;
    }
`;

export const Whatsapp = styled.a`
    color: #113E41;
    border: 1px solid #113E41;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    padding: 14px;
    display: flex;
    margin-left: 14px;
    @media screen and (max-width: 610px) {
        margin-top: 4px;
        margin-left: 0px;
        justify-content: center;
    }
`;

export const ProfileContent = styled.div`
    padding: 40px;
`;

export const Profile = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 1109px) {
        align-items: center;
        justify-content: center;
    }

    div {
        margin-left: 8px;
    }
`;

export const Name = styled.p`
    font-family: Yanone Kaffeesatz;
    font-style: normal;
    font-weight: normal;
    font-size: 30.5455px;
    line-height: 30px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #113E41;
`;

export const Cargo = styled.p`
    font-family: Yanone Kaffeesatz;
    font-style: normal;
    font-weight: 300;
    font-size: 20.3636px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* text */

    color: #7F8A84;
`;

export const Address = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 37.95px;
        height: 40.15px;
    }

    div {
        margin-left: 30px;
    }
`;

export const MainAddress = styled.p`
    font-family: Yanone Kaffeesatz;
    font-style: normal;
    font-weight: 600;
    font-size: 20.0727px;
    line-height: 20px;
    display: flex;
    align-items: center;

    /* Dark-Green */

    color: #113E41;
`;

export const FullAddress = styled.p`
    font-family: Yanone Kaffeesatz;
    font-style: normal;
    font-weight: normal;
    font-size: 17.5636px;
    line-height: 21px;
    /* or 121% */

    display: flex;
    align-items: center;

    /* text */

    color: #7F8A84;
`;

export const Agende = styled.a`
    text-decoration: none;
    margin-top: 56px;
    font-family: Yanone Kaffeesatz;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    /* or 119% */

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* Green */

    color: #4BB975;

    border: 1px solid #4BB975;
    box-sizing: border-box;
    border-radius: 8px;

    padding: 14px;
`;

export const Middle = styled.div`
    display: flex;
    background: #eef0f1;
    padding: 40px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

`;

export const BoardList = styled.div`
    width: 100%;    
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const MiddleContent = styled.div`
    padding: 40px 80px;
    width: 100%;
    max-width: 1440px;

    @media screen and (max-width: 640px) {
        padding: 20px;
    }
`;

export const Board = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(17, 62, 65, 0.1);
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 400px;
    height: 250px;

    @media screen and (max-width: 1400px) {
        width: 300px;
    }

    @media screen and (max-width: 1090px) {
        width: 200px;
    }

    @media screen and (max-width: 780px) {
        width: 150px;
    }

    @media screen and (max-width: 640px) {
        width: 175px;
    }

    @media screen and (max-width: 560px) {
        width: 125px;
    }

    p {
        margin-top: 8px;
        font-family: Yanone Kaffeesatz;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        text-align: center;

        /* text */

        color: #7F8A84;
    }
`;

export const WhoContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 1440px;
    margin: 0px auto;

    @media screen and (max-width: 690px) {
        padding: 16px;
    }
`;

export const Who = styled.div`
    background: #0f383a;
    
    padding: 80px 0px;

    p {
        width: 100%;
    }

    img {
        width: 350.67px;
        height: 344.73px;

        @media screen and (max-width: 1029px) {
            display: none;
        }

    }
`;

export const Text = styled.div`
    h2 {
        font-family: Yanone Kaffeesatz;
        font-style: normal;
        font-weight: 200;
        font-size: 64px;
        line-height: 63px;
        display: flex;
        align-items: center;

        /* Green */

        color: #4BB975;
    }

    p {
        padding: 8px;
        max-width: 652px;
        margin-top: 16px;
        font-family: Yanone Kaffeesatz;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        /* or 117% */

        text-align: justify;
        letter-spacing: 0.02em;

        /* Bg */

        color: #F1F3F4;
    }
`;

export const Footer = styled.div`
    width: 100%;
    background: #eef0f1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RightFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 720px;

    @media screen and (max-width: 1109px) {
        width: 350px;
    }
`;

export const  LeftFooter = styled.div`
    div {
        img {
            width: 30.29px;
            height: 32.01px;
        }
    }
`;

export const Contact = styled.a`
    display: flex;
    align-items: center;
    width: 350px;
    text-decoration: none;

    @media screen and (max-width: 1109px) {
        margin-bottom: 4px;
    }

    p {
        font-family: Yanone Kaffeesatz;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height */

        letter-spacing: 0.04em;

        /* text */

        color: #7F8A84;
    }

    div {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        width: 64px;
        
        background: #113E41;
        border: 2px solid #4BB975;
        box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.25);
        border-radius: 50%;

        svg {
            color: #fff;
        }
    }
`;

export const CopyRight = styled.div`
    width: 100%;
    background: #113E41;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-family: Yanone Kaffeesatz;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.04em;

        color: #FFFFFF;

        opacity: 0.6;
    }
`;

export const FooterContent = styled.div`
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: space-around;
    padding: 80px;
    width: 100%;

    @media screen and (max-width: 705px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;