import {Header, Underline, HeaderContent, LeftHeader, RightHeader, Option, Logo, Principal, Phrase, Quote, Author, Content,
Informations, LeftInformation, RightInformation, BottomInformations, Doctorlia, Whatsapp , Profile, Name, Cargo, ProfileContent, MiddleContent,
Address, MainAddress, FullAddress, Agende, Middle, BoardList, Board, Who, Text, WhoContent, Footer, RightFooter, LeftFooter, Contact, CopyRight, FooterContent} from './styles';

import {FaRegCheckCircle, FaAngleRight, FaCouch, FaFacebookF, FaWhatsapp, FaRegEnvelope, FaInstagram} from 'react-icons/fa';

import logo from '../../assets/images/logo.png';
import logoFooter from '../../assets/images/logo footer.png';
import profile from '../../assets/images/profile.png';
import local from '../../assets/images/local.svg';
import brain from '../../assets/images/brain.png';
import { useRef } from 'react';

function Home() {

  const contatoRef = useRef(document.createElement('div'));

  const executeScroll = (element) =>
    element.current.scrollIntoView();

    return (
      <>
        <Header>
          <HeaderContent>
            <LeftHeader>
              <Logo src={logo} />
            </LeftHeader>
            <RightHeader>
              <Option>
                Home
                <Underline />
              </Option>
              <Option
                onClick={() => {
                  executeScroll(contatoRef);
                }}
              >
                Contato
              </Option>
            </RightHeader>
          </HeaderContent>
        </Header>
        <Principal>
          <Content>
            <Phrase>
              <Quote>
                "O paradoxo curioso é que quando eu me aceito como eu sou, então eu mudo."
              </Quote>
              <Author>
                Carl Rogers
              </Author>
            </Phrase>

            <Informations>
              <LeftInformation>
                <h2>Psicólogo Clínico</h2>
                <ul>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Terapia adolescentes, adultos e idosos</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Transtorno Obsessivo Compulsivo (TOC)</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Transtorno de ansiedade</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Síndrome do pânico e fobias</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Depressão</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Compulsão alimentar e bulimia nervosa</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>LGBT: questões relacionadas à diversidade sexual e de gênero</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Problemas familiares e conflitos de relacionamento</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Baixa auto-estima e insegurança</p>
                  </li>
                  <li>
                    <FaRegCheckCircle color='#4BB975'/> <p>Alterações do humor</p>
                  </li>
                </ul>
                <BottomInformations>
                  <Doctorlia target="_blank" href="https://www.doctoralia.com.br/ricardo-diniz-2/psicologo/santo-andre3">Agendar consulta pelo Doctorlia <FaAngleRight /> </Doctorlia>
                  <Whatsapp target="_blank" href="https://api.whatsapp.com/send?phone=+5511949332222">Agendar consulta pelo WhatsApp <FaAngleRight /></Whatsapp>
                </BottomInformations>
              </LeftInformation>
              <RightInformation>
                <ProfileContent>
                  <Profile>
                    <img src={profile} alt="Foto de Ricardo Diniz" /> 
                    <div>
                      <Name>Ricardo Diniz</Name>
                      <Cargo>Psicólogo</Cargo>
                    </div>
                  </Profile>
                  <Address>
                    <img src={local} alt="Icone de localização" />
                    <div>
                      <MainAddress>SALA ABC - coworking psi</MainAddress>
                      <FullAddress>R. Catequese, 1171 - sala 93 <br />Centro, Santo André - SP, 09090-401</FullAddress>
                    </div>
                  </Address>
                  <Agende target="_blank" href="https://api.whatsapp.com/send?phone=+5511949332222">
                    Agende sua consulta <FaAngleRight />
                  </Agende>
                </ProfileContent>
              </RightInformation>
            </Informations>

          </Content>
        </Principal>
      
        <Middle>
          <MiddleContent>
            <BoardList>
              <Board>
                <>
                  <FaCouch color='#4BB975' size={64}/>
                  <p>
                    Sessões de terapia <br />
                    com valores acessíveis
                  </p>
                </>
              </Board>
              <Board>
                <FaCouch color='#4BB975' size={64}/>
                <p>
                  Entre em contato e <br />
                  agende uma conversa
                </p>
              </Board>
              <Board>
                <FaCouch color='#4BB975' size={64}/>
                <p>
                  Respeito e sigilo fazem<br />
                  parte do processo
                </p>
              </Board>
            </BoardList>
          </MiddleContent>
        </Middle>

        <Who>
          <WhoContent>
            <Text>
              <h2>Quem sou eu?</h2>
              <p>
              Me chamo Ricardo, e decidi estudar psicologia por ser fascinado pela singularidade e complexidade dos sujeitos. Me dedico, desde minha formação, ao estudo das teorias e práticas clínicas. O meu trabalho clínico é estruturado nos pressupostos da abordagem psicanalítica, portanto, sigo apoiado no tripé analítico (análise pessoal; estudo teórico e supervisão clínica).
              </p>
              <p>
              Busco aprendizado constante, através do estudo teórico, leituras frequentes, grupos de estudos e cursos.
              </p>
              <p>
              Por meio da supervisão busco articular o universal da teoria com o individual de cada paciente.
              </p>
              <p>
              Fico feliz com sua visita em meu site e espero poder te ajudar facilitando o início ou continuação do seu processo psicoterapêutico.
              </p>
              <p>
              Entre em contato, será um prazer te conhecer!
              </p>
            </Text>
            <img src={brain} alt="Imagem de um cerebro" />
          </WhoContent>
        </Who>

        <iframe
            title="Titulo unico"
            width="100%"
            height="505"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDPNsNBxxua53d7OScFmv8me7njDzPBbSs&q=Rua+Catequese+1171+Centro,Santo+Andre+SP+09090-401"
          />

        <Footer ref={contatoRef}>
          <FooterContent>
            <LeftFooter>
              <img src={logoFooter} alt="Logo do Ricardo Diniz" />
              <Address>
                <img src={local} alt="Icone de localização" />
                <div>
                  <MainAddress>SALA ABC - coworking psi</MainAddress>
                  <FullAddress>R. Catequese, 1171 - sala 93 <br />Centro, Santo André - SP, 09090-401</FullAddress>
                </div>
              </Address>
            </LeftFooter>
            <RightFooter>
              <Contact target="_blank" href="https://api.whatsapp.com/send?phone=+5511949332222">
                <div>
                  <FaWhatsapp size={24} />
                </div> 
                <p>
                  Whatsapp: 11 94933-2222
                </p>
              </Contact>
              <Contact target="_blank" href="mailto:contato@ricardopsi.com.br">
                <div>
                  <FaRegEnvelope size={24} />
                </div> 
                <p>
                  contato@ricardopsi.com.br
                </p>
              </Contact>
              <Contact target="_blank" href="https://www.facebook.com/ricardodiniz.psi">
                  <div>
                    <FaFacebookF size={24} />
                  </div> 
                  <p>
                    facebook.com/ricardodiniz.psi
                  </p>
                </Contact>
                <Contact target="_blank" href="https://www.instagram.com/ricardodiniz.psi/">
                  <div>
                    <FaInstagram size={24} />
                  </div> 
                  <p>
                    @ricardodiniz.psi
                  </p>
                </Contact>
            </RightFooter>
          </FooterContent>
        </Footer>

        <CopyRight>
          <p>Copyright 2021 - Feito por Adux.com.br</p>
        </CopyRight>
      </>
    );
  }
  
export default Home;
  